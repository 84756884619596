/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import { useMedia } from "react-use"

import Column from "../components/column"
import Container from "../components/container"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectCard from "../components/project-card"
import Row from "../components/row"

import { mediaQueryList } from "../helpers"

import { useIsClient } from "../hooks/use-is-client"

import refreshIcon from "../images/refresh-icon.svg"

const ProjectGalleryPage = ({ data }) => {
  const isClient = useIsClient()
  const mediaQueryMatch = useMedia(mediaQueryList({ mediaPrefix: false })[1])
  const extraCardsAmount = !isClient || (isClient && mediaQueryMatch) ? 7 : 4
  const fullWidthList = [true, true, true]
  const projects = data.allContentfulProject.edges.map(project => project.node)
  const refreshButtonSize = [20, 20, 40]
  const [cardsLimit, setCardsLimit] = React.useState(4)

  React.useEffect(() => {
    setCardsLimit(!isClient || (isClient && mediaQueryMatch) ? 7 : 4)
  }, [isClient, mediaQueryMatch])

  return (
    <Page data={data.contentfulPage}>
      <Container fullWidth={fullWidthList}>
        <Row noGutters={fullWidthList}>
          <Column noGutters={fullWidthList}>
            {
              <Hero
                background={{
                  type: "image",
                  source: {
                    mobileImage: data.hero.mobileImage,
                    desktopImage: data.hero.desktopImage,
                  },
                }}
                body={
                  JSON.parse(data.hero.description.description).content[0]
                    .content[0].value
                }
                title={data.hero.title}
                type="secondary"
                textWidth={576}
                verticalAlign="center"
              />
            }
          </Column>
        </Row>
      </Container>
      <Container>
        <Row customStyle={{ my: 3 }}>
          {projects.map((project, index) => {
            return index < cardsLimit ? (
              <Column
                customStyle={{
                  mb: theme => theme.spacing.horizontal,
                  ":last-child": { mb: 0 },
                }}
                size={[12, 12, index % 5 < 2 ? 6 : 4]}
                key={project.id}
              >
                <ProjectCard
                  background={project.projectImages[0].fluid.src}
                  link={{
                    to: `/projects/${project.slug}`,
                    type: "internal",
                  }}
                  text={[project.projectTitle.projectTitle].concat(
                    project.projectLocation.projectLocation
                  )}
                  type="tertiary"
                />
              </Column>
            ) : null
          })}
        </Row>
        {
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 4,
            }}
          >
            <button
              sx={{
                bg: "transparent",
                border: "none",
                display: cardsLimit < projects.length ? "block" : "none",
                p: 0,
              }}
              onClick={() => {
                setCardsLimit(cardsLimit + extraCardsAmount)
              }}
            >
              <img
                sx={{
                  display: "block",
                  height: refreshButtonSize,
                  width: refreshButtonSize,
                }}
                src={refreshIcon}
                alt="Show more models button"
              />
            </button>
          </div>
        }
      </Container>
    </Page>
  )
}

export default ProjectGalleryPage

export const query = graphql`
  query ProjectGalleryPageQuery {
    contentfulPage(slug: { eq: "project-gallery" }) {
      ...PageData
    }

    hero: contentfulBasicCard(slug: { eq: "project-gallery-hero" }) {
      ctaTitle
      description {
        description
      }
      title
      ctaDestination {
        ctaDestination
      }
      desktopImage {
        file {
          contentType
          url
        }
      }
    }

    allContentfulProject(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          projectImages {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
        }
      }
    }
  }
`
